<template>
  <div>
    <b-overlay
      :show="state.loading"
      rounded="sm"
    >
      <auth-modal
        :fino-datas="finoDatas"
        :auth-modal="authModal"
        :userInfo="userData"
        @hidden="authModal=false"
        @handleAuth="changeAuth($event)"
        @updateData="getData"
        :national-id="nationalID"
        :link="link"
      />
      <bank-modal
        :bankData="finoBankDatas"
        :userInfo="userData"
        :bank-modal="bankModal"
        :account.sync="current_account"
        @hidden="bankModal=false"
        @handleAuth="changeBankStatus"
        :national-id="nationalID"
        :link="link"
      />
      <!-- Alert: No item found -->
      <b-alert v-if="isNaN(Number(id))"
               variant="danger"
               :show="true"
      >
        <h4 class="alert-heading">
          خطا در برقراری ارتباط
        </h4>
        <div class="alert-body">
          کاربری با این شماره پیدا نشد. برای دیدن کاربران به بخش
          <b-link
            class="alert-link"
            :to="{ name: 'Accounts'}"
          >
            لیست کاربران
          </b-link>
          مراجعه فرمایید
        </div>
      </b-alert>

      <template v-else>
        <template>
          <!-- First Row -->
          <b-row>
            <b-col
              cols="12"
              xl="9"
              lg="8"
              md="7"
            >
              <user-view-user-info-card
                :user-data="userData"
                @change-status="changeStatus"
                @change-auth="changeAuthModal"
                @force-auth="forceAuthModal"
                @edit="sendData"
                v-if="$havePermission('DETAILS_USERS')"
              />
            </b-col>
            <b-col
              cols="12"
              md="5"
              xl="3"
              lg="4"
            >
              <user-view-user-plan-card
                :userData="userData"
                class="position-sticky"
                style="top:100px"
              />
            </b-col>
            <b-col>
              <bank-accounts
                :bankAccounts="userData.accounts"
                :auth="userData.status"
                :check="check"
                :key="randomNumber"
                @checkAccount="changeBank($event)"
                v-if="$havePermission('DETAILS_USERS')"
              />
            </b-col>
          </b-row>
        </template>

        <user-setting v-if="userData.settings" :settings="userData.settings"
                      @changeSetting="changeSetting($event)"/>

        <user-wallets v-if="$havePermission('LIST_WALLET') && userData.credits" :data="userData.credits"/>

        <accounts v-if="$havePermission('LIST_USERS')"/>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import Accounts from "@/views/UserAccounts/Accounts";
import UserViewUserInfoCard from "@/views/UserAccounts/UserViewUserInfoCard";
import UserViewUserPlanCard from "@/views/UserAccounts/UserViewUserPlanCard";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import UserWallets from "@/views/UserAccounts/userWallets";
import AuthModal from "@/views/UserAccounts/AuthModal";
import BankAccounts from "@/views/UserAccounts/BankAccounts";
import BankModal from "@/views/UserAccounts/BankModal";
import UserSetting from "@/views/UserAccounts/UserSetting";

export default {
  name: "showAccount",
  components: {
    UserSetting,
    BankModal,
    BankAccounts,
    AuthModal,
    UserWallets,
    UserViewUserPlanCard,
    UserViewUserInfoCard,
    Accounts,
    BRow,
    BCol,
    BAlert,
    BLink,
    BOverlay
  },
  props: ['id'],

  data() {
    return {
      userData: {
        id: 1,
        email: "",
        username: '',
        status: "",
        created_at: "",
        updated_at: "",
        profile: {},
        isActive: true,
        is_banned: false,
        rank: {},
        credits: [],
        accounts: []
      },
      finoDatas: {
        fullname: '',
        percent: 0,
      },
      finoBankDatas: {},
      nationalID: '',
      link: '',
      authModal: false,
      bankModal: false,
      check: [],
      randomNumber: 0,
      current_account: {}
    }
  },

  methods: {
    async changeSetting(e) {

      this.state.loading = true
      const address = '/users/' + this.userData.id + '/settings'
      await this.$axios.patch(address, {
        setting_value: e
      })

      await this.getData(this.currentPage, this.perPage)

      this.$swal({
        icon: 'success',
        title: 'تنظیمات کاربر به ' + e + ' تغییر کرد',
        confirmButtonText: 'تایید',
        // text: 'Your file has been deleted.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })

    },
    changeStatus() {
      this.$swal({
        title: 'آیا از تغییر وضعیت کاربر مطمئن هستید؟',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'خیر',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.state.loading = true
          const address = '/users/' + this.userData.id + '/' + (this.userData.is_banned ? 'un' : '') + 'ban'
          const res = await this.$axios.post(address)

          !res.data.message.includes('activated')
          await this.getData(this.currentPage, this.perPage)

          this.$swal({
            icon: 'success',
            title: !this.userData.is_banned ? 'کاربر فعال شد' : 'کاربر غیر فعال شد',
            confirmButtonText: 'تایید',
            // text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async changeBankStatus() {

      this.state.loading = true
      const res = await this.$axios.patch('/accounts/' + this.current_account.id + '/status', {
        status: this.current_account.status
      }).catch(() => {
      })

      if (res) {
        this.$swal({
          icon: 'success',
          title: 'وضعیت حساب بروز شد.',
          confirmButtonText: 'تایید',
          // text: 'Your file has been deleted.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    async changeBank(e) {
      this.check[e] = true
      this.state.loading = true
      this.randomNumber = Math.random()
      const res = await this.$axios.post('https://arztrading.com/auth/api/card-to-iban', {
        card: e.card_number,
      })
      this.finoBankDatas = res.data.data

      this.check[e] = false
      this.randomNumber = Math.random()
      this.current_account = e
      this.bankModal = true
    },
    async changeAuthModal() {
      this.state.loading = true
      try {
        const res = await this.$axios.post('https://arztrading.com/auth/api/nid-verification', {id: this.userData.id})
        let data = res.data.data
        this.finoDatas.fullName = data.result ? data.result.fullName : ''
        this.finoDatas.percent = Number(data.result ? data.result.fullNameSimilarity : 0)
        this.finoDatas.live = data.result ? data.result.deathStatus : ''
        this.nationalID = data.result ? data.result.nationalCode : ''
        this.birthday = data.result ? data.result.birthDate : ''

      } catch (e) {
        // this.finoDatas.fullname = 'عدم وجود اطلاعات'
      }

      this.authModal = true
    },
    async forceAuthModal() {

      const res = await this.$swal({
        icon: 'warning',
        title: 'آیا از تایید حساب مطمئن هستید؟',
        showCancelButton: true,
        confirmButtonText: 'تایید حساب',
        cancelButtonText: 'لغو',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-secondary ml-1',
        },
      })
      if (res.isConfirmed) {
        const res = await this.$axios.patch('/users/' + this.userData.id, {
          status: 'ACCEPTED'
        })
        let msg = ''
        msg = 'کاربر تایید شد'
        if (res.data.data.status === 'CONFIRMED_IDENTITY') {
          msg += 'برای احراز هویت نهایی حتما باید یک حساب تایید شده داشته باشد.'
        }
        this.$swal({
          icon: 'success',
          title: msg,
          confirmButtonText: 'متوجه شدم',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        this.getData()
      }
    },
    async changeAuth(e) {
      this.state.loading = true

      let url = e ? '/users/' + this.userData.id + '/accept' : '/users/' + this.userData.id + '/reject'

      const res = await this.$axios.post(url, {
        status: e,
      })

      let msg = ''
      if (e) {
        msg = 'کاربر تایید شد'
        if (res.data.data.status === 'CONFIRMED_IDENTITY') {
          msg += 'برای احراز هویت نهایی حتما باید یک حساب تایید شده داشته باشد.'
        }
      } else {
        msg = 'کاربر تایید نشد'
      }

      this.$swal({
        icon: e ? 'success' : 'warning',
        title: msg,
        confirmButtonText: 'تایید',
        // text: 'Your file has been deleted.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })

      this.getData()
    },
    // async sendData() {
    //     this.state.loading = true
    //
    //     let FD = new FormData()
    //     delete this.userData.file
    //     for (let index in this.userData) {
    //         console.log('aaaa', index, this.userData[index])
    //         FD.append(index, this.userData[index])
    //     }
    //
    //     const res = await this.$axios.post('/users/details/' + this.id, FD, {headers: {enctype: 'multipart/form-data'}})
    //     if (res)
    //         await this.getData()
    // },
    async sendData() {
      this.state.loading = true

      const res = await this.$axios.patch('/users/' + this.id + '/profile', {
        name: this.userData.profile.name,
        lastname: this.userData.profile.lastname,
        address: this.userData.profile.address,
        phone: this.userData.profile.phone,
        mobile: this.userData.profile.mobile,
        national_code: this.userData.profile.national_code,
      })
      if (res)
        await this.getData()
    },
    async getData() {
      this.state.loading = true

      const res = await this.$axios('/users/' + this.id)

      this.userData = res.data.data
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
