<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <small class="text-muted w-100">آپدیت شذه در تاریخ :
          {{ $G2J(userData.updated_at) }}
      </small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">زیر مجموعه های مورد نیاز : 0</span>
        </li>
        <li class="my-25">
          <span class="align-middle">حداقل معاملات : {{[0].toLocaleString()}} تومان</span>
        </li>
      </ul>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  // BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    // BButton,
  },
    props:['userData']
}
</script>

<style>

</style>
